// import version from package.json
var package_version = require('../../package.json');


export default function Footer() {

    return (

        <footer className="w-full">
            <div className="px-4 py-2 mx-auto xs:py-3 max-full sm:px-6 md:flex md:items-center md:justify-between lg:px-8">
                <div className="flex items-center justify-center space-x-4 md:order-2">


                    {/* {navigation.map((item) => (
                        <a key={item.name} href={item.href} className="text-gray-600 dark:text-gray-300 hover:text-gray-900 dark:hover:text-white">
                            <span className="sr-only">{item.name}</span>
                            <item.icon className="w-6 h-6" aria-hidden="true" />
                        </a>
                    ))} */}
                    <a className="text-sm text-blue-500 xs:text-xs dark:text-gray-300" target="_blank" rel="noreferrer"
                        href="https://docs.microsoft.com/en-gb/azure/cognitive-services/translator/language-support">Translator Ref</a>

                <span className="" >-</span>

                    <p className="text-sm text-gray-600 xs:text-xs dark:text-gray-300">v {(package_version.version) ? package_version.version : '0.0'}</p>
                </div>
                <div className="mt-4 md:mt-0 md:order-1">
                    <p className="text-sm text-center text-gray-600 xs:text-xs dark:text-gray-300">&copy; {`${new Date().getFullYear()}`} Ghost Blog Manager - PeerXP</p>
                </div>
            </div>
        </footer>
    )
}
