
export default function convertTZ(date, tzString) {
    let options = {
        month : 'long',
        day: 'numeric',
        year: 'numeric',
        hour: "2-digit",
        minute: "2-digit",
        timeZoneName : 'short',
        timeZone: tzString
    };


    if (date) {
        var date_time = new Date((typeof date === "string" ? new Date(date) : date).toLocaleString("en-US", { timeZone: tzString }));
        return date_time.toLocaleString("en-US", options);
    }
    else {
        return null;
    }
}

// return date_time.toString().replace("(India Standard Time)", "");
