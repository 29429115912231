// import react component
import React from 'react'
// import react table functions
import { useTable, usePagination, useRowSelect } from 'react-table'
// import heroic solid icons
import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/solid'
// import react responsive
import { useMediaQuery } from 'react-responsive';
// import No data Message component
import NoDataMessageComponent from "../Components/NoDataMessageComponent";



const IndeterminateCheckbox = React.forwardRef(
  ({ indeterminate, ...rest }, ref) => {
    const defaultRef = React.useRef();
    const resolvedRef = ref || defaultRef;

    React.useEffect(() => {
      resolvedRef.current.indeterminate = indeterminate;
    }, [resolvedRef, indeterminate]);

    return (
      <input type="checkbox" className="checkbox" ref={resolvedRef} {...rest} />
    );
  }
);


export default function Table({
  columns,
  data,
  fetchData,
  loading,
  pageCount: controlledPageCount,
  showFooter = true,
  manualPagination = true,
  showRowSelect = false,
  noDataInfo = {},
  maxHeight = false,
  selectedRow = (e) => e,
  resetSelectedRow = "",
  deletedPosts = []
}) {

  const isDesktopOrLaptop = useMediaQuery({ minDeviceWidth: 768 })
  const isTabletOrMobile = useMediaQuery({ maxWidth: 768 })

  // Use the state and functions returned from useTable to build your UI
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    gotoPage,
    nextPage,
    previousPage,
    // Get the state from the instance
    state: { pageIndex, pageSize, selectedRowIds },
    toggleAllPageRowsSelected,
  } = useTable({
    columns,
    data,
    initialState: { pageSize: 5, pageIndex: 0 },
    manualPagination: manualPagination,
    pageCount: controlledPageCount,
    autoResetSelectedRows: false,
    autoResetPage: manualPagination,
    getRowId: (row) => row.key,
    stateReducer: (newState, action, prevState) => {
      console.log(action);

      switch (action.type) {
        case 'toggleAllPageRowsSelected':
          // if action value is unselect
          if (action.value === "Unselect") {
            // reset selected row IDs
            return {
              ...newState,
              selectedRowIds: {},
            };
          }
          else {
            return newState;
          }

        default:
          return newState;
      }
    }
  },
    usePagination,
    useRowSelect,
    (hooks) => {
      // if props has showRowSelect as true we will show select row option
      if (showRowSelect) {
        hooks.visibleColumns.push((columns) => [
          // Let's make a column for selection
          {
            id: "selection",
            // The header can use the table's getToggleAllRowsSelectedProps method
            // to render a checkbox
            Header: ({ getToggleAllPageRowsSelectedProps }) => (
              <div>
                <IndeterminateCheckbox {...getToggleAllPageRowsSelectedProps()} />
              </div>
            ),
            // The cell can use the individual row's getToggleRowSelectedProps method
            // to the render a checkbox
            Cell: ({ row }) => (

              <div>
                <IndeterminateCheckbox {...row.getToggleRowSelectedProps()} />
              </div>

            )
          },
          ...columns
        ]);
      }
    },
  );

  const [newPageIndex, setNewPageIndex] = React.useState(0);

  React.useEffect(() => {
    setNewPageIndex(pageIndex + 1);
  }, [pageIndex])

  // Listen for changes in pagination and use the state to fetch our new data
  React.useEffect(() => {
    fetchData({ pageSize, pageIndex });
  }, [fetchData, pageIndex, pageSize])


  // listen for changes in selected row and use the state to set selected row to parent component
  React.useEffect(() => {
    const selectedIds = Object.keys(selectedRowIds);

    selectedRow(selectedIds);
  }, [selectedRowIds, selectedRow, data]);


  // listen for posts url change and delepost length and unselect all the rows to parent component
  React.useEffect(() => {
    // pass argument as unselect to unselect all the selected row 
    toggleAllPageRowsSelected("Unselect");
  }, [resetSelectedRow, deletedPosts.length, toggleAllPageRowsSelected]);


  // go to page 1 if post url changes
  React.useEffect(() => {
    gotoPage(0);
  }, [resetSelectedRow, gotoPage]);


  // Render the UI for your table
  return (
    <>
      {
        (!loading && data.length > 0) &&

        <div className="flex flex-col">
          <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
            <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
              <div className="overflow-hidden bg-white border border-gray-300 shadow dark:bg-gray-900 xs:shadow-none sm:rounded-lg dark:border-gray-500 xs:border-0">

                {/* FOR DESKTOP AND LAPTOP DEVICES */}

                {
                  isDesktopOrLaptop &&

                  <table {...getTableProps()} className="min-w-full divide-y divide-gray-300 dark:divide-gray-600">
                    <thead className={`${maxHeight ? "block" : ""} bg-gray-200 dark:bg-gray-600`}>
                      {
                        headerGroups.map(headerGroup => (
                          <tr {...headerGroup.getHeaderGroupProps()}>
                            {
                              headerGroup.headers.map(column => (
                                <th {...column.getHeaderProps()}
                                  scope="col"
                                  className={`${maxHeight ? "w-full" : ""}  px-4 py-3 text-xs font-medium tracking-wider text-left text-gray-600 bg-gray-200 dark:bg-gray-600 dark:text-gray-300`}>
                                  {column.render('Header')}
                                </th>
                              ))
                            }
                          </tr>
                        ))
                      }
                    </thead>
                    <tbody
                      {...getTableBodyProps()}
                      className={`${maxHeight ? "block h-80 overflow-x-hidden overflow-y-scroll" : ""} bg-white divide-y divide-gray-300 dark:bg-gray-800 dark:divide-gray-600`} >
                      {
                        page.map((row, i) => {
                          prepareRow(row)
                          return (
                            <tr {...row.getRowProps()} >
                              {
                                row.cells.map(cell => {
                                  return <td
                                    {...cell.getCellProps()}
                                    className={`${maxHeight ? "w-full" : ""} px-4 py-4 text-sm text-gray-900  whitespace-nowrap dark:text-gray-300`}
                                  >
                                    {cell.render('Cell')}
                                  </td>
                                })
                              }
                            </tr>

                          )
                        })
                      }
                    </tbody>
                  </table>

                }

                {/* FOR MOBILE AND TABLET DEVICES */}

                {
                  isTabletOrMobile &&

                  <>
                    {
                      maxHeight &&

                      <table {...getTableProps()} className="min-w-full border border-gray-300 divide-y divide-gray-300 dark:border-gray-500 -dark:divide-gray-600">
                        <thead className={`${maxHeight ? "block" : ""} bg-gray-200 dark:bg-gray-600`}>
                          {
                            headerGroups.map(headerGroup => (
                              <tr {...headerGroup.getHeaderGroupProps()} >
                                {
                                  headerGroup.headers.map(column => (
                                    <th {...column.getHeaderProps()}
                                      scope="col"
                                      className={`${maxHeight ? "w-full" : ""} px-4 py-3 text-xs font-medium tracking-wider text-left text-gray-600 bg-gray-200 dark:bg-gray-600 dark:text-gray-300`}>
                                      {column.render('Header')}
                                    </th>
                                  ))
                                }
                              </tr>
                            ))
                          }
                        </thead>
                        <tbody
                          {...getTableBodyProps()}
                          className={`${maxHeight ? "block h-80 overflow-x-hidden overflow-y-scroll" : ""} bg-white divide-y divide-gray-300 dark:bg-gray-800 dark:divide-gray-600`} >
                          {
                            page.map((row, i) => {
                              prepareRow(row)
                              return (
                                <tr {...row.getRowProps()} className="flex flex-col" >
                                  {
                                    row.cells.map(cell => {
                                      return <td
                                        {...cell.getCellProps()}
                                        className={`${maxHeight ? "w-full" : ""} px-4 py-4 text-sm text-gray-900  whitespace-nowrap dark:text-gray-300`}
                                      >
                                        {cell.render('Cell')}
                                      </td>
                                    })
                                  }
                                </tr>

                              )
                            })
                          }
                        </tbody>
                      </table>
                    }

                    {
                      !maxHeight &&
                      <div className="py-6 mx-auto max-w-7xl sm:py-2 xs:py-0 sm:px-6 lg:px-8">
                        <div className="mx-auto max-w-none">

                          {
                            page.map((row, i) => {
                              prepareRow(row)
                              return (
                                <div {...row.getRowProps()} className="mt-4 overflow-hidden border border-gray-300 rounded-lg dark:border-gray-600" >
                                  <ul className="divide-y divide-gray-300 dark:divide-gray-600" >

                                    {
                                      row.cells.map((cell, index) => {
                                        return <li {...cell.getCellProps()}>
                                          <span className="block hover:bg-gray-200 dark:hover:bg-gray-800">
                                            <div className="px-4 py-4 sm:px-6">
                                              <div className="flex items-center justify-between">
                                                {
                                                  headerGroups.length &&

                                                  headerGroups[0].headers[index].render('Header') &&

                                                  <div className="text-xs dark:text-gray-300" {...headerGroups[0].headers[index].getHeaderProps()} >
                                                    {
                                                      headerGroups[0].headers[index].render('Header')
                                                    }
                                                  </div>
                                                }

                                                <div
                                                  className={
                                                    `ml-2 text-gray-900 dark:text-gray-300 xs:text-right xs:text-sm ${(headerGroups.length && headerGroups[0].headers[index].render('Header')) ? '' : 'w-full'}`
                                                  }
                                                  {...cell.getCellProps()}
                                                >

                                                  {cell.render('Cell')}

                                                </div>
                                              </div>
                                            </div>
                                          </span>
                                        </li>

                                      })
                                    }
                                  </ul>
                                </div>

                              )
                            })
                          }


                        </div>
                      </div>

                    }

                  </>

                }

                {
                  showFooter &&

                  <div className="flex items-center justify-between px-4 py-3 bg-white border-t border-gray-300 rounded-b-lg xs:border-0 dark:bg-gray-800 xs:dark:bg-transparent xs:bg-transparent dark:border-gray-600 sm:px-6">
                    <div className="flex justify-between flex-1 sm:hidden" >
                      <span

                        className="relative inline-flex items-center px-4 py-2 text-sm font-medium text-gray-900 bg-white border border-gray-300 rounded shadow-sm cursor-pointer hover:bg-gray-200 dark:border-gray-500 dark:bg-gray-900 dark:hover:bg-gray-800 dark:text-gray-300 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 dark:focus:ring-offset-gray-900 focus:ring-blue-300"
                        onClick={() => previousPage()} disabled={!canPreviousPage}
                      >
                        Previous
                      </span>
                      <span

                        className="relative inline-flex items-center px-4 py-2 text-sm font-medium text-gray-900 bg-white border border-gray-300 rounded shadow-sm cursor-pointer hover:bg-gray-200 dark:border-gray-500 dark:bg-gray-900 dark:hover:bg-gray-800 dark:text-gray-300 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 dark:focus:ring-offset-gray-900 focus:ring-blue-300"
                        onClick={() => nextPage()} disabled={!canNextPage}
                      >
                        Next
                      </span>
                    </div>

                    <div className="hidden sm:flex-1 sm:flex sm:items-center sm:justify-between">
                      <div>
                        <p className="text-sm text-gray-900 dark:text-gray-300">
                          Page  <span className="font-medium">{pageIndex + 1}</span> of <span className="font-medium">{pageOptions.length}</span>
                        </p>
                      </div>
                      <div>
                        <nav className="relative z-0 inline-flex -space-x-px rounded-md shadow-sm" aria-label="Pagination">
                          <span

                            className="relative inline-flex items-center px-2 py-2 text-sm font-medium text-gray-500 bg-white border border-gray-300 cursor-pointer rounded-l-md dark:border-gray-500 dark:hover:bg-gray-500 hover:bg-gray-200 dark:bg-gray-600 dark:text-gray-300"

                            onClick={(e) => { e.preventDefault(); previousPage(); }} disabled={!canPreviousPage}
                          >
                            <span className="sr-only">Previous</span>
                            <ChevronLeftIcon className="w-5 h-5" aria-hidden="true" />
                          </span>

                          <>
                            {
                              ((newPageIndex - 2) > 0) &&

                              <span

                                className="relative inline-flex items-center px-4 py-2 text-sm font-medium text-gray-900 bg-white border border-gray-300 cursor-pointer dark:border-gray-500 dark:hover:bg-gray-500 hover:bg-gray-200 dark:bg-gray-600 dark:text-gray-300"

                                onClick={(e) => { e.preventDefault(); gotoPage(newPageIndex - 3); }} disabled={!canNextPage}
                              >
                                {newPageIndex - 2}
                              </span>
                            }

                            {
                              ((newPageIndex - 1) > 0) &&

                              <span

                                className="relative inline-flex items-center px-4 py-2 text-sm font-medium text-gray-900 bg-white border border-gray-300 cursor-pointer dark:border-gray-500 dark:hover:bg-gray-500 hover:bg-gray-200 dark:bg-gray-600 dark:text-gray-300"

                                onClick={(e) => { e.preventDefault(); gotoPage(newPageIndex - 2); }} disabled={!canNextPage}
                              >
                                {newPageIndex - 1}
                              </span>
                            }

                            {

                              ((newPageIndex - 1) === pageIndex) &&

                              <span

                                onClick={(e) => e.preventDefault()}
                                className="relative inline-flex items-center px-4 py-2 text-sm font-medium text-gray-300 bg-blue-500 border border-blue-500 cursor-pointer dark:border-blue-500"
                              >
                                {newPageIndex}
                              </span>
                            }

                            {
                              ((newPageIndex + 1) <= pageOptions.length) &&
                              <span

                                className="relative inline-flex items-center px-4 py-2 text-sm font-medium text-gray-900 bg-white border border-gray-300 cursor-pointer dark:border-gray-500 dark:hover:bg-gray-500 hover:bg-gray-200 dark:bg-gray-600 dark:text-gray-300"

                                onClick={(e) => { e.preventDefault(); gotoPage(newPageIndex); }} disabled={!canNextPage}
                              >
                                {newPageIndex + 1}
                              </span>
                            }

                            {
                              ((newPageIndex + 2) <= pageOptions.length) &&

                              <span

                                className="relative inline-flex items-center px-4 py-2 text-sm font-medium text-gray-900 bg-white border border-gray-300 cursor-pointer dark:border-gray-500 dark:hover:bg-gray-500 hover:bg-gray-200 dark:bg-gray-600 dark:text-gray-300"

                                onClick={(e) => { e.preventDefault(); gotoPage(newPageIndex + 1); }} disabled={!canNextPage}
                              >
                                {newPageIndex + 2}
                              </span>
                            }

                          </>

                          <span

                            className="relative inline-flex items-center px-2 py-2 text-sm font-medium text-gray-500 bg-white border border-gray-300 cursor-pointer rounded-r-md dark:border-gray-500 dark:hover:bg-gray-500 hover:bg-gray-200 dark:bg-gray-600 dark:text-gray-300"
                            onClick={(e) => { e.preventDefault(); nextPage(); }} disabled={!canNextPage}
                          >
                            <span className="sr-only">Next</span>
                            <ChevronRightIcon className="w-5 h-5" aria-hidden="true" />
                          </span>
                        </nav>
                      </div>
                    </div>
                  </div>

                }

              </div>
            </div>
          </div>
        </div >
      }

      {/* No DATA INFO SECTION */}
      {
        (!loading && data.length === 0) &&
        <NoDataMessageComponent {...noDataInfo} />
      }

    </>
  )
}
