// import react component
import React, { useState, useEffect, useCallback } from 'react';
// import headers
import Header from "../Components/Header";
// import content component
import Content from "../Components/Content";
// import react select
import Select from 'react-select';
// import notfication alert
import { useSnackbar } from 'notistack';
// import loader component
// import Loader from '../Components/Loader';
// import react responsive component
import { useMediaQuery } from 'react-responsive';
// import toggle switch component
import ToggleSwitch from '../Components/ToggleSwitch';
// import table component
import Table from '../Components/Table';
// import css
import '../Styles/vegatheme.css';
// import react select
import '../Styles/React_Select.css';
import { postsURL, translatorURL } from '../Components/URL';


// The admin API client is the easiest way to use the API
// const GhostAdminAPI = require('@tryghost/admin-api');

const axios = require('axios');

// Admin API key goes here
const {
    REACT_APP_TUTORIALS_URL,
    REACT_APP_BLOGS_URL,
} = process.env;


function HomePage() {

    // show alert and close alert functions
    const { enqueueSnackbar } = useSnackbar();

    const isDesktopOrLaptop = useMediaQuery({ minDeviceWidth: 768 });

    // Initialize states
    const [values, setValues] = useState({
        url: "",
        languages: [],
    });

    // ghost get api response
    const [GhostResponseData, setGhostResponseData] = useState({});

    // ghost post api response
    const [GhostPostResponseData, setGhostPostResponseData] = useState([]);

    // for loader
    // const [loading, setLoading] = useState(false);

    const [ToggleOn, setToggleOn] = useState(false);

    // set Language Options
    const languageOptions = [
        { label: "Spanish", value: "es" },
        { label: "French", value: "fr" },
        { label: "German", value: "de" },
        { label: "Japanese", value: "ja" },
        { label: "Arabic", value: "ar" },
        { label: "Chinese", value: "zh-Hans" },
        { label: "Russian", value: "ru" },
        { label: "Vietnamese", value: "vi" },
        { label: "Indonesian", value: "id" },
        { label: "Dutch", value: "nl" },
        { label: "Italian", value: "it" },
    ];

    const [tableData, setTableData] = React.useState([]);

    const [ShowCheckConsoleMessage, setShowCheckConsoleMessage] = React.useState(false);


    var translatePost;

    // ghots api for  GET / POST method
    const GhostAPI = useCallback((method, data = {}, language = {}) => {
        // initialize empty key 
        // let key = "";
        let url = "";
        let slug = "";

        try {
            if (values.url.includes(REACT_APP_TUTORIALS_URL)) {
                // assign TUTORIALS ADMIN API KEY
                // key = REACT_APP_TUTORIALS_ADMIN_API;
                // assign URL
                url = REACT_APP_TUTORIALS_URL;
                // assign slug
                // let temp = values.url.split(REACT_APP_TUTORIALS_URL);
                let temp = values.url.split(REACT_APP_TUTORIALS_URL);
                slug = temp[temp.length - 1];
            }
            else if (values.url.includes(REACT_APP_BLOGS_URL)) {
                // assign BLOGS ADMIN API KEY
                // key = REACT_APP_BLOGS_ADMIN_API;
                // assign URL
                url = REACT_APP_BLOGS_URL;
                // assign slug
                let temp = values.url.split(REACT_APP_BLOGS_URL);
                slug = temp[temp.length - 1];
            }

            // replace all slash in url slug
            slug = slug.replaceAll("/", '');

            // GhostAdminAPI 
            // Configure the client
            // const api = new GhostAdminAPI({
            //     url: url,
            //     // Admin API key goes here
            //     key: key,
            //     version: 'v3',
            // });

            if (method === "GET") {
                // console.log(slug);

                // GET method to get blog using slug
                axios.get(postsURL, {
                    params: { slug: slug, formats: 'html', blog_url: url },
                }).then(response => {
                    console.log(response);
                    // exract  posts from response
                    let posts = response["data"]["posts"];

                    // check posts response
                    if (posts.length > 0) {
                        setGhostResponseData(posts[0]);
                    }
                    else {
                        enqueueSnackbar(`No Data Found.`, {
                            variant: 'error',
                            autoHideDuration: 3000,
                        });
                    }
                })
                    .catch(error => {
                        if (error.response && error.response.status >= 400) {
                            setShowCheckConsoleMessage(true);
                        }

                        console.error(error); enqueueSnackbar(`${error}`, {
                            variant: 'error',
                            autoHideDuration: 3000,
                        });

                    });
            }
            else if (method === "POST") {

                let datas = data.split("||");

                // console.log(datas);

                let html = `<!--kg-card-begin: html--> ${datas[11]} <!--kg-card-end: html-->`;

                // console.log(html);

                let content = {
                    "custom_excerpt": datas[0].includes("null") ? ' ' : datas[0].slice(0, 300),
                    "excerpt": datas[1].includes("null") ? ' ' : datas[1].slice(0, 300),
                    "meta_description": datas[2].includes("null") ? ' ' : datas[2],
                    "meta_title": datas[3].includes("null") ? ' ' : datas[3],
                    "og_description": datas[4].includes("null") ? ' ' : datas[4],
                    "og_title": datas[5].includes("null") ? ' ' : datas[5],
                    'title': datas[6].includes("null") ? ' ' : datas[6],
                    "twitter_description": datas[7].includes("null") ? ' ' : datas[7],
                    "twitter_title": datas[8].includes("null") ? ' ' : datas[8],
                    "feature_image": GhostResponseData.feature_image,
                    "feature_image_alt": datas[9].includes("null") ? ' ' : datas[9],
                    "feature_image_caption": datas[10].includes("null") ? ' ' : datas[10],
                    "html": html,
                    "status": "draft",
                    "authors": GhostResponseData.authors.map((res) => res.email),
                    "tags": [`#${language.value}`, ...GhostResponseData.tags.map((res) => res.name)],
                }

                // console.log("content", content);

                // Make an post request
                axios.post(postsURL, {
                    data: content,
                    params: { blog_url: url, source: 'html' },
                }).then(response => {
                    console.log(response);

                    // posts response
                    enqueueSnackbar(`Translated Post In ${language.label} Language`, {
                        variant: 'success',
                        autoHideDuration: 3000,
                    });

                    let response_data = response["data"];
                    response_data['language'] = language.label;
                    // set ghost post data to show in UI
                    setGhostPostResponseData(oldArray => [...oldArray, response_data]);

                    setTableData(oldArray => [...oldArray, {
                        key: language.label,
                        language: language.label,
                        link: response_data["posts"].length > 0 ? <a target="_blank" rel="noreferrer" className="text-blue-500" href={response_data["posts"][0].url} >{response_data["posts"][0].url}</a> :
                            <span className="cursor-pointer inline-flex items-center px-2.5 py-0.5 rounded text-xs font-medium">
                                NA
                            </span>
                    }]);
                })
                    .catch(error => {
                        if (error.response && error.response.status >= 400) {
                            setShowCheckConsoleMessage(true);
                        }

                        console.error(error); enqueueSnackbar(`${error}`, {
                            variant: 'error',
                            autoHideDuration: 3000,
                        });

                        // set ghost post data to show in UI
                        setGhostPostResponseData(oldArray => [...oldArray, null]);

                        setTableData(oldArray => [...oldArray, {
                            key: language.label,
                            language: language.label,
                            link: <span onClick={() => { translatePost([language]); }} className="cursor-pointer inline-flex items-center px-2.5 py-0.5 rounded text-xs font-medium bg-red-500 text-white">
                                Retry
                            </span>
                        }]);

                    });

            }
        }
        catch (error) {
            // console.log(error);
            enqueueSnackbar(`Please check the URL and try again.`, {
                variant: 'error',
                autoHideDuration: 3000,
            });
        }
    }, [GhostResponseData.authors, enqueueSnackbar, values.url, GhostResponseData.feature_image, GhostResponseData.tags, translatePost]);


    // function to translate post
    translatePost = React.useCallback((languages) => {

        console.log(`${GhostResponseData.html}`.length);

        setShowCheckConsoleMessage(false);

        const translate = (text, language) => {

            return axios.post(translatorURL, {
                languages: [language.value],
                text: text
            })
        }

        let itemsProcessed = 0;

        const max_limit = 10000;

        // iterate each language
        languages.forEach(async (language, index, array) => {

            // assign meta data
            let meta_data = `${GhostResponseData.custom_excerpt ? GhostResponseData.custom_excerpt : ' '}||${GhostResponseData.excerpt ? GhostResponseData.excerpt : ' '}||${GhostResponseData.meta_description ? GhostResponseData.meta_description : " "}||${GhostResponseData.meta_title}||${GhostResponseData.og_description ? GhostResponseData.og_description : ' '}||${GhostResponseData.og_title}||${GhostResponseData.title ? GhostResponseData.title : ' '}||${GhostResponseData.twitter_description ? GhostResponseData.twitter_description : ' '}||${GhostResponseData.twitter_title ? GhostResponseData.twitter_title : ' '}||${GhostResponseData.feature_image_alt ? GhostResponseData.feature_image_alt : ' '}||${GhostResponseData.feature_image_caption ? GhostResponseData.feature_image_caption : ' '}`;

            // console.log("html length", GhostResponseData.html.toString().length, meta_data);

            // set table data status as translating
            setTableData(oldArray => [...oldArray, {
                key: language.label,
                language: language.label,
                link: "Translating..."
            }]);

            // if blog html content is greater than max_limit
            if (GhostResponseData.html.toString().length > max_limit) {

                // split the content by 9000 and then call sequential api
                const [firstResponse, secondResponse, thirdResponse] = await Promise.all([
                    // translates meta data
                    translate(meta_data, language),
                    // translates 0 to 9000 words in blog
                    translate(GhostResponseData.html.toString().substr(0, GhostResponseData.html.toString().substr(0, max_limit).lastIndexOf(' ')), language),
                    // translates 9000 to 1800 words in blog
                    translate(GhostResponseData.html.toString().substr(GhostResponseData.html.toString().substr(0, max_limit).lastIndexOf(' '), max_limit * 2), language),
                ]).catch((error) => {
                    if (((error || {}).response || {}).status >= 400) {
                        setShowCheckConsoleMessage(true);
                    }

                    console.log(error);
                    enqueueSnackbar(`${error}`, {
                        variant: 'error',
                        autoHideDuration: 3000,
                    });

                    // set ghost post data to show in UI
                    setGhostPostResponseData(oldArray => [...oldArray, null]);

                    setTableData(oldArray => [...oldArray, {
                        key: language.label,
                        language: language.label,
                        link: <span onClick={() => { translatePost([language]); }} className="cursor-pointer inline-flex items-center px-2.5 py-0.5 rounded text-xs font-medium bg-red-500 text-white">
                            Retry
                        </span>
                    }]);
                });

                // console.log(`${firstResponse.data[0].translations[0].text}`, language);
                GhostAPI("POST", `${firstResponse.data[0].translations[0].text}||${secondResponse.data[0].translations[0].text}${thirdResponse.data[0].translations[0].text}`, language);
            }
            else {

                translate(`${meta_data}||${GhostResponseData.html}`, language)
                    .then(function (response) {
                        // console.log(JSON.stringify(response.data, null, 4));

                        if (response.data[0].translations.length) {
                            GhostAPI("POST", response.data[0].translations[0].text, language);
                        }

                    }).catch((error) => {
                        if (error.response && error.response.status >= 400) {
                            setShowCheckConsoleMessage(true);
                        }

                        console.error(error); enqueueSnackbar(`${error}`, {
                            variant: 'error',
                            autoHideDuration: 3000,
                        });

                        // set ghost post data to show in UI
                        setGhostPostResponseData(oldArray => [...oldArray, null]);

                        setTableData(oldArray => [...oldArray, {
                            key: language.label,
                            language: language.label,
                            link: <span onClick={() => { translatePost([language]); }} className="cursor-pointer inline-flex items-center px-2.5 py-0.5 rounded text-xs font-medium bg-red-500 text-white">
                                Retry
                            </span>
                        }]);

                    }); // end catch error
            } // end else

            // increment by one for each loop
            itemsProcessed++;

            if (itemsProcessed === array.length) {
                // set Ghost response data as empty
                setGhostResponseData({});
            }

        }); //end forEach

        // return true;
    }, [enqueueSnackbar, GhostResponseData, GhostAPI, translatePost])


    useEffect(() => {

        if (GhostResponseData.hasOwnProperty("html")) {

            translatePost(values.languages);
        }

    }, [GhostResponseData, values.languages, translatePost]);



    const tableColumns = React.useMemo(
        () => [
            {
                Header: "Language",
                accessor: 'language',
            },
            {
                Header: "Ghost Draft Post Link",
                accessor: 'link'
            }
        ],
        []
    );

    // function to save inputs in states
    const handleInputChange = (e) => {
        // takes input name and value from event target input field
        const { name, value } = e.target;

        // sets state values
        setValues({
            ...values,
            [name]: value,
        });
    };

    const handleFormSubmit = (event) => {
        event.preventDefault();

        setGhostPostResponseData([]);

        setTableData(values.languages.map((data) => {
            return { key: data.label, language: data.label, link: "Translating..." }
        }));

        GhostAPI("GET");

    }


    return (
        <>
            {/* pass current page as props as true */}
            <Header home={true} hideSearchBar={true} />

            <Content >
                <p className="text-lg font-medium text-gray-900 dark:text-gray-300">Ghost Blog Manager (GBM)</p>

                <div className={isDesktopOrLaptop ? "flex gap-6" : " "}>

                    <div className="w-full py-4">
                        <div className="mt-5 md:mt-0">
                            <form action="#" method="POST" onSubmit={handleFormSubmit}>
                                <div className="shadow sm:rounded">
                                    <div className="formbody">
                                        <div className="grid grid-cols-6 gap-6">
                                            <div className="col-span-6">
                                                <label htmlFor="url" className="form-label">
                                                    Enter Original Post URL (Blogs/Tutorials - en)
                                                </label>
                                                <input
                                                    type="url"
                                                    name="url"
                                                    id="url"
                                                    value={values.url}
                                                    onChange={handleInputChange}
                                                    className="input-field"
                                                    required={true}
                                                    placeholder="ex: https://vegastack.com/tutorials/how-to-find-your-ip-address-in-linux/"
                                                />
                                            </div>

                                            <div className="col-span-6">
                                                <div className="flex items-center justify-between">
                                                    <label htmlFor="street_address" className="mt-2 form-label">
                                                        Select Translator Languages
                                                    </label>

                                                    <ToggleSwitch label=" All" checked={ToggleOn} onChange={(result) => {
                                                        setToggleOn(result);

                                                        setValues({
                                                            ...values,
                                                            languages: result ? languageOptions : [],
                                                        })
                                                    }} />
                                                </div>

                                                <Select
                                                    name="languages"
                                                    id="languages"
                                                    value={values.languages}
                                                    selectOption
                                                    onChange={(result) => {
                                                        setValues({
                                                            ...values,
                                                            languages: result,
                                                        })
                                                    }}
                                                    options={languageOptions}
                                                    isMulti={true}
                                                    required={true}
                                                    className="mt-2"
                                                    placeholder="Select Languages"
                                                />
                                                <input
                                                    type="text"
                                                    name="language_required"
                                                    id="language_required"
                                                    defaultValue={values.languages.length ? "true" : ''}
                                                    className="h-0.5 opacity-0"
                                                    required={true}
                                                />
                                            </div>

                                        </div>
                                    </div>
                                    <div className="formfooter">
                                        <button
                                            type="submit"
                                            className="button form-button-primary"
                                        >
                                            Translate
                                        </button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>

                    {/* response section */}
                    <div className="w-full py-4">
                        <div className="mt-5 md:mt-0">
                            <div className="px-4 shadow sm:rounded formbody">

                                {
                                    GhostPostResponseData.length === 0 && tableData.length === 0 &&

                                    <>
                                        <p className="mb-4 font-medium">Supported Translator Languages</p>
                                        <ol className="ml-8 list-decimal">
                                            {
                                                languageOptions.map((data) => {
                                                    return <li key={data.label}>{data.label}</li>
                                                })
                                            }
                                        </ol>
                                    </>
                                }

                                {
                                    ShowCheckConsoleMessage &&

                                    <p className="mt-4 mb-4 font-medium">An error occurred. Please check console logs.</p>
                                }

                                <Table
                                    columns={tableColumns}
                                    data={tableData}
                                    fetchData={React.useCallback(() => { }, [])}
                                    loading={false}
                                    pageCount={0}
                                    showFooter={false}
                                />

                            </div>
                        </div>
                    </div>
                </div>

            </Content>
        </>
    );
}

export default HomePage;
