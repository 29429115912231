
// import heroic icons solid
import { PlusIcon } from '@heroicons/react/solid';
// import react router dom
import { Link } from "react-router-dom";

function NoDataMessageComponent(props) {

    return (

        <div className="py-4 text-center">
            {
                props.icon && <props.icon className="w-8 h-8 mx-auto text-gray-1300 dark:text-gray-1300" />
            }

            {props.title && <h3 className="mt-2 text-sm font-medium text-gray-900 dark:text-gray-1500">{props.title}</h3>}
            {props.content && <p className="mt-1 text-sm text-gray-500 dark:text-gray-1300" >{props.content} </p>}
            <div className="mt-6">
                {
                    props.button_name &&

                    <Link to={props.url ? props.url : "#"}
                        className="inline-flex items-center px-4 py-2 text-sm font-medium text-white bg-indigo-600 border border-transparent rounded-md shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500" >
                        <PlusIcon className="w-5 h-5 mr-2 -ml-1" aria-hidden="true" />
                        {props.button_name}
                    </Link>
                }
            </div>
        </div>
    )
}


export default NoDataMessageComponent;