// import react content loader component
import ContentLoader from "react-content-loader";


function TableLoader(props) {

    // const backgroundcolor = (localStorage.getItem('theme') === 'light' || localStorage.getItem('theme') === null) ? '#EAEAEA' : "#242424";

    const backgroundcolor = '#EAEAEA';
    const foregroundcolor = '#FCFCFC';

    return (
        <div className="flex flex-col mb-4">
            <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                    <div className="overflow-hidden shadow sm:rounded-lg">
                        <table className="min-w-full divide-y divide-gray-300 dark:divide-gray-600">
                            <thead className="bg-gray-200 dark:bg-gray-600">

                                <tr >
                                    {
                                        new Array(props.columns).fill(1).map((_, index) => {

                                            return (
                                                <th key={index} scope="col" className="px-4 py-3 text-xs font-medium tracking-wider text-left text-gray-600 bg-gray-200 dark:bg-gray-600 dark:text-gray-300">
                                                    <ContentLoader
                                                        speed={2}
                                                        height={12}
                                                        style={{ width: "100%" }}
                                                        backgroundColor={backgroundcolor}
                                                        foregroundColor={foregroundcolor}
                                                        className="react_content_loader"
                                                    >
                                                        <rect x="0" y="0" rx="5" ry="5" style={{ width: '100%' }} height="12" />
                                                    </ContentLoader>
                                                </th>
                                            )
                                        })

                                    }

                                </tr>

                            </thead>
                            <tbody className="bg-white divide-y divide-gray-300 dark:bg-gray-800 dark:divide-gray-600">
                                {

                                    new Array(props.rows ? props.rows : 5).fill(1).map((_, index) => {

                                        return (
                                            <tr key={index} role="row">
                                                {
                                                    new Array(props.columns).fill(1).map((result, index) => {

                                                        if (props.avatar && (props.avatar.index - 1) === index) {

                                                            return (<td key={index} className="px-4 py-4">
                                                                <ContentLoader
                                                                    speed={2}
                                                                    height={48}
                                                                    style={{ width: "100%" }}
                                                                    backgroundColor={backgroundcolor}
                                                                    foregroundColor={foregroundcolor}
                                                                    className="react_content_loader"
                                                                >
                                                                    <rect x="0" y="0" rx="5" ry="5" style={{ width: "50px" }} height="46" />

                                                                    <rect x="54" y="8" rx="5" ry="5" style={{ width: '50%' }} height="16" />

                                                                    <rect x="54" y="32" rx="5" ry="5" style={{ width: '75%' }} height="12" />

                                                                </ContentLoader>
                                                            </td>)
                                                        }

                                                        else if (props.circleAvatar && (props.circleAvatar.index - 1) === index) {

                                                            return (<td key={index} className="px-4 py-4">
                                                                <ContentLoader
                                                                    speed={2}
                                                                    height={40}
                                                                    style={{ width: "100%" }}
                                                                    backgroundColor={backgroundcolor}
                                                                    foregroundColor={foregroundcolor}
                                                                    className="react_content_loader"
                                                                >
                                                                    <rect x="54" y="4" rx="5" ry="5" style={{ width: "60%" }} height="16" />

                                                                    <rect x="54" y="28" rx="5" ry="5" style={{ width: '80%' }} height="12" />

                                                                    <circle cx="20" cy="20" r="20" />

                                                                </ContentLoader>
                                                            </td>)
                                                        }
                                                        else if (props.headingDescription && (props.headingDescription.index - 1) === index) {

                                                            return (<td key={index} className="px-4 py-4">
                                                                <ContentLoader
                                                                    speed={2}
                                                                    height={40}
                                                                    style={{ width: "100%" }}
                                                                    backgroundColor={backgroundcolor}
                                                                    foregroundColor={foregroundcolor}
                                                                    className="react_content_loader"
                                                                >
                                                                    <rect x="0" y="4" rx="5" ry="5" style={{ width: "60%" }} height="16" />

                                                                    <rect x="0" y="28" rx="5" ry="5" style={{ width: '80%' }} height="12" />

                                                                </ContentLoader>
                                                            </td>)
                                                        }
                                                        else {

                                                            return (<td key={index} className="px-4 py-4">
                                                                <ContentLoader
                                                                    speed={2}
                                                                    height={40}
                                                                    style={{ width: "100%" }}
                                                                    backgroundColor={backgroundcolor}
                                                                    foregroundColor={foregroundcolor}
                                                                    className="react_content_loader"
                                                                >
                                                                    <rect x="0" y="10" rx="5" ry="5" style={{ width: '100%' }} height="20" />
                                                                </ContentLoader>
                                                            </td>)

                                                        }
                                                    })
                                                }
                                            </tr>
                                        )
                                    })

                                }
                            </tbody>
                        </table>

                        <div className="flex items-center justify-between px-4 py-3 bg-white border-t border-gray-300 dark:bg-gray-800 dark:border-gray-600 sm:px-6">
                            <div className={`${props.hideFooter ? "hidden" : ""}`}>
                                <div className="flex justify-between flex-1 sm:hidden" >
                                    <ContentLoader
                                        speed={2}
                                        height={38}
                                        style={{ width: "20%" }}
                                        backgroundColor={backgroundcolor}
                                        foregroundColor={foregroundcolor}
                                        className="react_content_loader"
                                    >
                                        <rect x="0" y="0" rx="5" ry="5" style={{ width: '100%' }} height="38" />
                                    </ContentLoader>


                                    <ContentLoader
                                        speed={2}
                                        height={38}
                                        style={{ width: "20%" }}
                                        backgroundColor={backgroundcolor}
                                        foregroundColor={foregroundcolor}
                                        className="react_content_loader"
                                    >
                                        <rect x="0" y="0" rx="5" ry="5" style={{ width: '100%' }} height="38" />
                                    </ContentLoader>
                                </div>
                                <div className="hidden sm:flex-1 sm:flex sm:items-center sm:justify-between">

                                    <ContentLoader
                                        speed={2}
                                        height={20}
                                        style={{ width: "90%" }}
                                        backgroundColor={backgroundcolor}
                                        foregroundColor={foregroundcolor}
                                        className="react_content_loader"
                                    >
                                        <rect x="0" y="0" rx="5" ry="5" style={{ width: '10%' }} height="20" />
                                    </ContentLoader>

                                    <ContentLoader
                                        speed={2}
                                        height={38}
                                        style={{ width: "10%" }}
                                        backgroundColor={backgroundcolor}
                                        foregroundColor={foregroundcolor}
                                        className="react_content_loader"
                                    >
                                        <rect x="0" y="0" rx="5" ry="5" style={{ width: '100%' }} height="38" />
                                    </ContentLoader>

                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div >
    )
}


export default TableLoader;