import Footer from "../Components/Footer";
// import classNames join function 
import { classNames } from "../Components/utilities";


export default function Content(props) {


    return (
        <div className={classNames(
            'relative mt-24 mb-0',
            props.showSidebar ? 'lg:ml-56 md:ml-56' : ''
        )}>

            {/* <div className="flex flex-col flex-1 bg-gray-100 dark:bg-gray-900"> */}
            {/* flex-1 focus:outline-none */}

            <main className="bg-gray-100 dark:bg-gray-900 min-h-content" >
                {/* <div className="py-3"> */}
                    <div className="max-w-full mx-auto px-4 sm:px-6 md:px-8">
                        {/* Replace with your content */}

                        {
                            props.children
                        }

                        {/* /End replace */}
                    </div>
                {/* </div> */}
            </main>

            {/* </div> */}

            <Footer />

        </div>
    )
}
