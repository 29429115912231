// import toggle switch component
import { Switch } from '@headlessui/react';
// import utilities
import { classNames } from '../Components/utilities';


function ToggleSwitch(props) {

    return (
        <Switch.Group as="div" className="flex items-center">
            <Switch
                checked={props.checked}
                onChange={props.onChange}
                className={classNames(
                    props.checked ? 'bg-indigo-600' : 'bg-gray-300 dark:bg-gray-600',
                    'relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 dark:focus:ring-offset-gray-900 focus:ring-blue-300'
                )}
            >
                {
                    props.label &&
                    <span className="sr-only">{props.label}</span>
                }
                <span
                    aria-hidden="true"
                    className={classNames(
                        props.checked ? 'translate-x-5' : 'translate-x-0',
                        'pointer-events-none inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200'
                    )}
                />
            </Switch>
            {
                props.label &&

                <Switch.Label as="span" className="ml-3">
                    <span className="text-sm text-gray-900 dark:text-gray-300">{props.label}</span>
                </Switch.Label>
            }

        </Switch.Group>
    )
}

export default ToggleSwitch;