// import react component
import React, { useState, useCallback } from 'react';
// import headers
import Header from "../Components/Header";
// import content component
import Content from "../Components/Content";
// import table component
import Table from '../Components/Table';
// import heroic icon 
import {
    EmojiSadIcon,
    ExternalLinkIcon
} from '@heroicons/react/outline';
// import react select
import Select from 'react-select';
// import notfication alert
import { useSnackbar } from 'notistack';
// import loader component
import TableLoader from "../Components/TableLoader";
// import timezone 
import { format } from 'timeago.js';
import convertTZ from '../Components/TimezoneHandler';
import axios from 'axios';
import { postDetailsURL, postsURL } from '../Components/URL';


// Admin API key goes here
const {
    // REACT_APP_TUTORIALS_ADMIN_API,
    // REACT_APP_BLOGS_ADMIN_API,
    REACT_APP_TUTORIALS_URL,
    REACT_APP_BLOGS_URL,
} = process.env;

// The admin API client is the easiest way to use the API
// const GhostAdminAPI = require('@tryghost/admin-api');


export default function Drafts() {


    // show alert and close alert functions
    const { enqueueSnackbar } = useSnackbar();

    // select post url state
    const [selectedPostsURL, setselectedPostsURL] = useState(
        { label: REACT_APP_TUTORIALS_URL, value: REACT_APP_TUTORIALS_URL },
    );

    const PostsUrlOptions = [
        { label: REACT_APP_TUTORIALS_URL, value: REACT_APP_TUTORIALS_URL },
        { label: REACT_APP_BLOGS_URL, value: REACT_APP_BLOGS_URL },
    ]

    // table state
    const [loading, setLoading] = React.useState(false);
    const [tableData, setTableData] = React.useState([]);
    const [totalPages, settotalPages] = React.useState(0);

    // delete data state
    const [deleteSelectedPosts, setDeleteSelectedPosts] = React.useState([]);
    const [deletedPosts, setdeletedPosts] = React.useState([]);

    const fetchIdRef = React.useRef(0);


    // const getApi = () => {
    //     // initialize empty key 
    //     let key = "";
    //     let url = "";

    //     if (selectedPostsURL.value === REACT_APP_TUTORIALS_URL) {
    //         // assign TUTORIALS ADMIN API KEY
    //         key = REACT_APP_TUTORIALS_ADMIN_API;
    //         // assign URL
    //         url = REACT_APP_TUTORIALS_URL;
    //     }
    //     else if (selectedPostsURL.value === REACT_APP_BLOGS_URL) {
    //         // assign BLOGS ADMIN API KEY
    //         key = REACT_APP_BLOGS_ADMIN_API;
    //         // assign URL
    //         url = REACT_APP_BLOGS_URL;
    //     }

    //     // GhostAdminAPI 
    //     // Configure the client
    //     const api = new GhostAdminAPI({
    //         url: url,
    //         // Admin API key goes here
    //         key: key,
    //         version: 'v3',
    //     });

    //     return api;
    // }


    const fetchPosts = useCallback(({ pageSize, pageIndex }) => {

        // Give this fetch an ID
        const fetchId = ++fetchIdRef.current

        // Set the loading state
        setLoading(true);

        if (fetchId === fetchIdRef.current) {

            // make a get request to backend
            // with pagination
            axios.get(postsURL, {
                params: {
                    formats: 'html',
                    filter: 'status:draft',
                    blog_url: selectedPostsURL.value,
                    page: pageIndex + 1
                },
            })
                // if success response
                .then((response) => {
                    console.log(response["data"]);

                    // exract  posts || meta from response
                    let posts = response["data"]["posts"];
                    let meta = response["data"]["meta"];

                    // intialize empty list
                    let new_data = [];

                    // loop through the datas and add new data to empty new_data array 
                    posts.map((result, index) => {
                        return new_data.push({
                            key: result.id,

                            title: result.title,

                            author: result.authors.length > 0 ? result.authors[0].name : "",

                            updated_at: format(convertTZ(result.updated_at, 'IST')),

                            external_link: <a href={result.url} target="__blank">
                                <ExternalLinkIcon className="w-5 h-5 ml-2 text-right" />
                            </a>,
                        })
                    });

                    // set table posts data
                    setTableData(new_data);

                    // set total page numbers
                    settotalPages(meta.pagination.pages);

                    // set table loader status as false
                    setLoading(false);
                }).catch((errors) => {
                    // console.log(errors.response);

                    try {
                        // exract error message from response and show the error
                        if (errors.response.hasOwnProperty("data") && errors.response.data["errors"][0].hasOwnProperty("message")) {
                            enqueueSnackbar(`${errors.response.data["errors"][0]["message"]}`, {
                                variant: 'error',
                                autoHideDuration: 3000,
                            });
                        }
                    }
                    catch (e) {
                        // else show custom error message

                        enqueueSnackbar(`An unknown error occcured`, {
                            variant: 'error',
                            autoHideDuration: 3000,
                        });

                    }
                    // set table loader status as false
                    setLoading(false);
                });

        }

    }, [enqueueSnackbar, selectedPostsURL.value]);


    const tableColumns = React.useMemo(
        () => [
            {
                Header: "Title",
                accessor: 'title',
            },
            {
                Header: "Author",
                accessor: 'author'
            },
            {
                Header: "External Link",
                accessor: 'external_link'
            },

            {
                Header: "Updated At",
                accessor: 'updated_at'
            },
        ],
        []
    );


    // function to delete posts    
    const deletePosts = () => {
        // set deleted posts state as empty
        setdeletedPosts([]);

        // initialize a empty list
        let new_deletedPosts = [];

        // iterate selected post to delete
        deleteSelectedPosts.map((id) => {

            // Make delete API request
            return axios.delete(postDetailsURL(id), {
                data: {
                    blog_url: selectedPostsURL.value,
                }
            }).then(response => {
                console.log(response);
                // if post is deleted
                // add the post id in deleted posts list
                new_deletedPosts.push(id);

                // if success show message
                enqueueSnackbar("successfully Deleted Posts", {
                    variant: 'success',
                    autoHideDuration: 3000,
                });

                fetchPosts({ pageSize: 0, pageIndex: 0 });

            })
                .catch(error => {

                    console.error(error);
                    // if error show message
                    enqueueSnackbar(`${error}`, {
                        variant: 'error',
                        autoHideDuration: 3000,
                    });

                });

            // // Make delete API request
            // return getApi().posts.delete({ id: id })
            //     .then(response => {
            //         console.log(response);
            //         // if post is deleted
            //         // add the post id in deleted posts list
            //         new_deletedPosts.push(id);

            //         // if success show message
            //         enqueueSnackbar("successfully Deleted Posts", {
            //             variant: 'success',
            //             autoHideDuration: 3000,
            //         });

            //         fetchPosts({ pageSize: 0, pageIndex: 0 });

            //     })
            //     .catch(error => {

            //         console.error(error);
            //         // if error show message
            //         enqueueSnackbar(`${error}`, {
            //             variant: 'error',
            //             autoHideDuration: 3000,
            //         });

            //     });
        });

        // set deleted posts in state
        setdeletedPosts(new_deletedPosts);

    }


    return (
        <>
            {/* pass current page as props as true */}
            <Header drafts={true} hideSearchBar={true} />

            <Content >
                <p className="text-lg font-medium text-gray-900 dark:text-gray-300">Draft List</p>

                <div className="flex items-center justify-between pt-4 align-middle">

                    <div className="w-full max-w-lg mr-4 lg:max-w-xs">
                        <Select
                            name="posts_url"
                            id="posts_url"
                            value={selectedPostsURL}
                            selectOption
                            onChange={(result) => {
                                setselectedPostsURL(result);
                            }}
                            options={PostsUrlOptions}
                            required={true}
                            className=""
                            placeholder="Select URL"
                        />
                    </div>

                    {
                        deleteSelectedPosts.length > 0 &&


                        <button
                            type="button"
                            className={" px-4 py-2 mt-3 mr-3 text-base font-medium text-white bg-red-600 border border-transparent rounded shadow-sm hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-600 focus:ring-offset-gray-100 dark:focus:ring-offset-gray-900 sm:w-auto sm:text-sm"}
                            onClick={() => deletePosts()}
                        >
                            Delete Posts
                        </button>
                    }
                </div>

                <div className="py-4">

                    {
                        loading &&

                        <TableLoader columns={2} headingDescription={{ index: 1 }} />

                    }

                    <Table
                        columns={tableColumns}
                        data={tableData}
                        fetchData={fetchPosts}
                        loading={loading}
                        pageCount={totalPages}
                        showRowSelect={true}
                        selectedRow={setDeleteSelectedPosts}
                        resetSelectedRow={selectedPostsURL.value}
                        deletedPosts={deletedPosts}
                        noDataInfo={
                            {
                                icon: EmojiSadIcon,
                                title: "No Posts Found",
                                content: " ",
                            }
                        }
                    />
                </div>


            </Content>
        </>
    );

}

