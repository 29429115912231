// import react router dom component
import {
  Route,
  Switch
} from "react-router-dom";
// import react component
import React from "react";
// import Home page component
import HomePage from "./Pages/Home";
// import drafts page component
import Drafts from "./Pages/Drafts";
import Analytics from "./Pages/Analytics";


function App() {

  // This function check for Environment variable
  // if ENV is production we will hide the console log.
  // React.useEffect(() => {
  //   const { NODE_ENV } = process.env;

  //   // if Environment is production
  //   if (NODE_ENV === "production") {
  //     console.log = () => { };
  //   }
  // }, [])


  return (
    <Switch >

      <Route exact path="/">
        <HomePage />
      </Route>

      <Route exact path="/drafts">
        <Drafts />
      </Route>

      <Route exact path="/analytics">
        <Analytics />
      </Route>

    </Switch >

  );
}

export default App;
