// import react component
import React, { useState } from 'react';
// import headers
import Header from "../Components/Header";
// import content component
import Content from "../Components/Content";
// import react select
import Select from 'react-select';
// import heroic icon 
import {
    ExternalLinkIcon
} from '@heroicons/react/outline';

// Admin API key goes here
const {
    REACT_APP_TUTORIALS_URL,
    REACT_APP_BLOGS_URL,
    REACT_APP_TUTORIALS_IFRAME_URL,
    REACT_APP_BLOGS_IFRAME_URL
} = process.env;



export default function Analytics() {

    // select post url state
    const [selectedPostsURL, setselectedPostsURL] = useState(
        { label: REACT_APP_TUTORIALS_URL, value: REACT_APP_TUTORIALS_URL },
    );

    const PostsUrlOptions = [
        { label: REACT_APP_TUTORIALS_URL, value: REACT_APP_TUTORIALS_URL },
        { label: REACT_APP_BLOGS_URL, value: REACT_APP_BLOGS_URL },
    ]


    return (
        <>
            {/* pass current page as props as true */}
            <Header analytics={true} hideSearchBar={true} />

            <Content >
                <p className="text-lg font-medium text-gray-900 dark:text-gray-300">Analytics</p>

                <div className="flex flex-col md:flex-row space-y-4 items-center justify-between pt-4 align-middle">

                    <div className="w-full max-w-lg lg:max-w-xs">
                        <Select
                            name="posts_url"
                            id="posts_url"
                            value={selectedPostsURL}
                            selectOption
                            onChange={(result) => {
                                setselectedPostsURL(result);
                            }}
                            options={PostsUrlOptions}
                            required={true}
                            className="w-full"
                            placeholder="Select URL"
                        />
                    </div>


                    {/* external link */}
                    {
                        selectedPostsURL?.value &&
                        <a
                            href={selectedPostsURL?.value === REACT_APP_TUTORIALS_URL ?
                                "https://pb.vegastack.com/vegastack.com%2Ftutorials"
                                :
                                "https://pb.vegastack.com/vegastack.com%2Fblog"
                            }
                            target="_blank"
                            rel="noopener noreferrer"
                            className='flex justify-start items-center'
                        >
                            Open Plausible
                            <ExternalLinkIcon className='h-5 w-5 ml-2' />
                        </a>
                    }
                </div>

                <div className="py-4">

                    {
                        selectedPostsURL?.value &&

                        <iframe
                            title={selectedPostsURL?.value === REACT_APP_TUTORIALS_URL ? 'tutorials' : 'blogs'}
                            plausible-embed={"true"}
                            src={selectedPostsURL?.value === REACT_APP_TUTORIALS_URL
                                ?
                                REACT_APP_TUTORIALS_IFRAME_URL
                                :
                                REACT_APP_BLOGS_IFRAME_URL
                            }
                            scrolling="no"
                            frameBorder="0"
                            loading="lazy"
                            style={{ width: "1px", minWidth: "100%", height: "1600px" }}
                        >
                        </iframe>
                    }

                </div>


            </Content>
        </>
    );

}

