// import react component
import { useRef, useEffect } from 'react';
// import jquery
import $ from "jquery";
// import react responsive
import { useMediaQuery } from 'react-responsive';



// function to get previous props or state
export default function usePrevious(value) {
  const ref = useRef();

  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
}


// set initial theme 
export function setInitialTheme() {

  // if theme is dark in localstorage or system default color is dark
  if (localStorage.theme === 'dark' || (!('theme' in localStorage) && window.matchMedia('(prefers-color-scheme: dark)').matches)) {
    // add classname as dark in html tag
    document.documentElement.classList.add('dark');

    // save theme mode as dark in local storage
    localStorage.setItem('theme', 'dark');
  } else {
    // remove classname dark from html tag
    document.documentElement.classList.remove('dark');

    // save theme mode as light in local storage
    localStorage.setItem('theme', 'light');
  }
}


// function to handle show or hide password 
export function handleShowPassword(id) {

  // get id and check input if input type is password change it to text else password
  $(`#${id}`).each(function () {
    if ($(this)[0].type === "password") {
      $(this)[0].type = "text";
    } else {
      $(this)[0].type = "password";
    }
  })

}


// funtion to change format of a date object
export function formatDate(date) {
  var dd = date.getDate();

  var mm = date.getMonth() + 1;
  var yyyy = date.getFullYear();

  if (dd < 10) {
    dd = '0' + dd;
  }

  if (mm < 10) {
    mm = '0' + mm;
  }

  return dd + '-' + mm + '-' + yyyy;
}

// funtion to change format of a date object to time format
export function formatTime(date) {
  var hour = date.getHours();

  var min = date.getMinutes();

  // var sec = date.getSeconds();

  if (hour < 10) {
    hour = "0" + hour;
  }

  if (min < 10) {
    min = "0" + min;
  }

  return hour + ':' + min;
}


// function to add class with conditional check
export function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}


export function HideInitialSidebarInMobile(params) {

  const isTabletOrMobile = useMediaQuery({ maxWidth: 768 })


  return isTabletOrMobile ? false : true;

}


export const copyIdToClipboard = (id) => {
  const el = document.createElement('textarea');
  el.value = `${id}`;	//str is your string to copy
  document.body.appendChild(el);
  el.select();
  document.execCommand('copy');	// Copy command
  document.body.removeChild(el);
}



// This function handles redirect to error page
// based on the error status code
export const redirectToErrorPage = (props, error) => {

  // check error has response and status
  if (error.response && error.response.status) {

    // assign status code in variable
    var status = error.response.status;

    // console.log(status, props);

    // checks status is 404
    if (status === 404) {
      // redirect to page not found page
      props.history.push('/page_not_found/');
    }
    // checks status is 403 forbidden
    else if (status === 403) {
      // redirect to forbidden page
      props.history.push('/forbidden/');
    }
    // checks status is 500 internal server error
    else if (status === 500) {
      // redirect to internal server error page
      props.history.push('/server_error/');
    }
  }
}
