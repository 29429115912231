// import react component
import { Fragment, useState } from 'react';
// import headless ui component
import { Disclosure, Menu, Transition, Popover } from '@headlessui/react'
// import heroic solid icon
import { SearchIcon } from '@heroicons/react/solid'
// import heroic icons
import {
    // MenuIcon,
    // XIcon,
    ChevronDownIcon,
    ExternalLinkIcon,
} from '@heroicons/react/outline';
// import router component
import { Link } from 'react-router-dom';
// import classNames function 
import { classNames } from "../Components/utilities";
// import avatar component
import Avatar from 'react-avatar';
// import vegastack logo component
import VegastackLogo from './VegastackLogo';


function Header(props) {

    // initialize state variables
    const values = {
        gitlab_instance_url: "",
        user_type: "",
        timezone: "",
        user_image: "",
        search: ""
    };

    const [setOpenSlideOverlay] = useState(false);

    const nav_links = [
        {
            name: "Home",
            href: "/",
            active: props.home
        },
        {
            name: "Drafts",
            href: "/drafts",
            active: props.drafts
        },
        {
            name: "Analytics",
            href: "/analytics",
            active: props.analytics
        },
    ]

    // loading image state
    const [avatarImageLoading, setavatarImageLoading] = useState(true);


    return (
        <>
            <Disclosure as="nav" className="fixed top-0 z-50 w-full bg-blue-500 dark:bg-gray-800">
                {({ open }) => (
                    <>
                        <div className="px-2 mx-auto max-full sm:px-4 lg:px-8">
                            <div className="relative flex items-center justify-between h-12">
                                <div className="flex items-center px-2 lg:px-0">
                                    <Link to="/" className="flex-shrink-0">

                                        <VegastackLogo className="block w-auto h-6 text-white fill-current" />

                                        { /* <VegamasterLogo className="hidden w-auto h-5 text-white fill-current lg:block" /> */}

                                    </Link>
                                    <div className="hidden lg:block ml-8">
                                        <div className="flex space-x-4">
                                            {/* Current: "bg-gray-800 text-white", Default: "text-gray-300 hover:bg-gray-600 hover:text-white" */}

                                            {

                                                nav_links.map((link_detail, index) =>

                                                    !link_detail.children ? (
                                                        <Link to={link_detail.href}
                                                            key={link_detail.href}
                                                            className={classNames(
                                                                link_detail.active ? 'dark:bg-gray-600 bg-blue-800 text-white focus:outline-none focus:bg-blue-800 hover:bg-blue-800 hover:text-white dark:focus:bg-gray-600 dark:hover:bg-gray-600  ' : 'text-white focus:outline-none focus:bg-blue-800 hover:bg-blue-800 hover:text-white dark:focus:bg-gray-600 dark:hover:bg-gray-600 ',
                                                                'px-3 py-2 rounded text-sm font-medium'
                                                            )}
                                                        >
                                                            {link_detail.name}
                                                        </Link>
                                                    ) :

                                                        (
                                                            <Menu as="div" className="relative inline-block text-left">
                                                                <div>
                                                                    <Menu.Button className="inline-flex justify-center w-full px-4 py-2 text-sm font-medium text-white rounded hover:bg-blue-800 dark:hover:bg-gray-600 focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75">
                                                                        {link_detail.name}
                                                                        <ChevronDownIcon
                                                                            className="w-4 h-5 ml-2 -mr-1 text-violet-200 hover:text-violet-100"
                                                                            aria-hidden="true"
                                                                        />
                                                                    </Menu.Button>
                                                                </div>
                                                                <Transition
                                                                    as={Fragment}
                                                                    enter="transition ease-out duration-100"
                                                                    enterFrom="transform opacity-0 scale-95"
                                                                    enterTo="transform opacity-100 scale-100"
                                                                    leave="transition ease-in duration-75"
                                                                    leaveFrom="transform opacity-100 scale-100"
                                                                    leaveTo="transform opacity-0 scale-95"
                                                                >
                                                                    <Menu.Items className="absolute left-0 w-40 mt-2 origin-top-right bg-white divide-y divide-gray-100 rounded shadow-lg dark:bg-gray-800 ring-1 ring-black ring-opacity-5 focus:outline-none">
                                                                        <div className="px-1 py-1">

                                                                            {
                                                                                link_detail.children.map((link) => {
                                                                                    return <Menu.Item>
                                                                                        {({ active }) => (
                                                                                            <a
                                                                                                key={link.href}
                                                                                                href={link.href}
                                                                                                target="_blank"
                                                                                                rel="noreferrer"

                                                                                                className={`${active ? 'text-gray-800 dark:text-white bg-gray-300 dark:bg-gray-600' : 'text-gray-900 dark:text-white'
                                                                                                    } group flex rounded items-center w-full px-2 py-2 text-sm font-medium cursor-pointer`}
                                                                                            >
                                                                                                {active ? (
                                                                                                    <ExternalLinkIcon
                                                                                                        className="w-5 h-5 mr-2"
                                                                                                        aria-hidden="true"
                                                                                                    />
                                                                                                ) : (
                                                                                                    <ExternalLinkIcon
                                                                                                        className="w-5 h-5 mr-2"
                                                                                                        aria-hidden="true"
                                                                                                    />
                                                                                                )}
                                                                                                {link.name}
                                                                                            </a>
                                                                                        )}
                                                                                    </Menu.Item>
                                                                                })
                                                                            }

                                                                        </div>
                                                                    </Menu.Items>
                                                                </Transition>
                                                            </Menu>
                                                        )
                                                )
                                            }
                                        </div>
                                    </div>

                                    <div className="ml-8 lg:hidden">
                                        <div className="flex space-x-4">
                                            <Link to={nav_links[0].href}
                                                key={nav_links[0].href}
                                                className={classNames(
                                                    nav_links[0].active ? 'dark:bg-gray-600 bg-blue-800 text-white focus:outline-none focus:bg-blue-800 hover:bg-blue-800 hover:text-white dark:focus:bg-gray-600 dark:hover:bg-gray-600  ' : 'text-white focus:outline-none focus:bg-blue-800 hover:bg-blue-800 hover:text-white dark:focus:bg-gray-600 dark:hover:bg-gray-600 ',
                                                    'px-3 py-2 rounded text-sm font-medium'
                                                )}
                                            >
                                                {nav_links[0].name}
                                            </Link>
                                            <Popover className="relative">
                                                {({ open }) => (
                                                    <>
                                                        <Popover.Button
                                                            className={classNames(
                                                                open ? 'dark:bg-gray-600 bg-blue-800 text-white focus:outline-none focus:bg-blue-800 hover:bg-blue-800 hover:text-white dark:focus:bg-gray-600 dark:hover:bg-gray-600  ' : 'text-white focus:outline-none focus:bg-blue-800 hover:bg-blue-800 hover:text-white dark:focus:bg-gray-600 dark:hover:bg-gray-600',
                                                                'flex px-3 py-2 rounded text-sm font-medium'
                                                            )}
                                                        >
                                                            <span>More</span>
                                                            <ChevronDownIcon
                                                                className={classNames(
                                                                    open ? 'text-gray-600' : 'text-gray-400',
                                                                    'ml-2 h-5 w-5 group-hover:text-gray-500'
                                                                )}
                                                                aria-hidden="true"
                                                            />
                                                        </Popover.Button>

                                                        <Transition
                                                            as={Fragment}
                                                            enter="transition ease-out duration-200"
                                                            enterFrom="opacity-0 translate-y-1"
                                                            enterTo="opacity-100 translate-y-0"
                                                            leave="transition ease-in duration-150"
                                                            leaveFrom="opacity-100 translate-y-0"
                                                            leaveTo="opacity-0 translate-y-1"
                                                        >
                                                            <Popover.Panel className="absolute z-10 left-1/2 transform -translate-x-1/2 mt-3 px-2 w-screen max-w-xs sm:px-2">
                                                                <div className="rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 overflow-hidden">
                                                                    <div className="relative grid gap-6 bg-white px-6 py-6 ">
                                                                        {nav_links.slice(1).map((link_detail) => (
                                                                            <Link
                                                                                to={link_detail.href}
                                                                                key={link_detail.href}
                                                                                className={
                                                                                    link_detail.active ?
                                                                                        "-m-3 p-3 block rounded-md bg-gray-200" :
                                                                                        "-m-3 p-3 block rounded-md hover:bg-gray-50"
                                                                                }
                                                                            >
                                                                                <p className="text-base font-medium text-gray-900">
                                                                                    {link_detail.name}
                                                                                </p>
                                                                            </Link>
                                                                        ))}
                                                                    </div>
                                                                </div>
                                                            </Popover.Panel>
                                                        </Transition>
                                                    </>
                                                )}
                                            </Popover>
                                        </div>
                                    </div>

                                </div>
                                {/* </div> */}
                                <div className="flex items-center justify-end flex-1 px-2 lg:ml-6 lg:justify-end lg:px-0">

                                    {
                                        !props.hideSearchBar &&

                                        <div className={"max-w-lg w-full lg:max-w-xs hidden lg:block"}>
                                            <label htmlFor="search" className="sr-only">
                                                Search
                                            </label>
                                            <div className="relative">
                                                <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                                                    <SearchIcon className="w-5 h-5 text-black dark:text-white text-opacity-30 dark:text-opacity-30" aria-hidden="true" />
                                                </div>
                                                <input
                                                    id="search"
                                                    name="search"
                                                    className="searchinput"
                                                    placeholder="Search"
                                                    type="search"
                                                    value={values.search}
                                                //   onChange={handleInputChange}
                                                //   onKeyUp={searchSubmitHandler}
                                                />
                                            </div>
                                        </div>
                                    }

                                    {
                                        !props.hideSearchBar &&

                                        <Link
                                            to={{ pathname: "/search/projects", search: `?q=` }}
                                            className="px-2 py-2 mr-4 text-white rounded focus:bg-blue-800 hover:bg-blue-800 dark:focus:bg-gray-600 dark:hover:bg-gray-600 hover:text-white focus:outline-none lg:hidden"
                                        >
                                            <SearchIcon className="w-6 h-6" aria-hidden="true" />
                                        </Link>
                                    }

                                </div>
                                <div className="flex lg:hidden">
                                    {/* Mobile menu button */}
                                    <div className="fixed top-16 w-56 text-right">
                                        <Menu as="div" className="relative inline-block text-left">
                                            <div>
                                                <Menu.Button className="inline-flex w-full justify-center rounded-md bg-black bg-opacity-20 px-4 py-2 text-sm font-medium text-white hover:bg-opacity-30 focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75">
                                                    Options
                                                    <ChevronDownIcon
                                                        className="ml-2 -mr-1 h-5 w-5 text-violet-200 hover:text-violet-100"
                                                        aria-hidden="true"
                                                    />
                                                </Menu.Button>
                                            </div>
                                            <Transition
                                                as={Fragment}
                                                enter="transition ease-out duration-100"
                                                enterFrom="transform opacity-0 scale-95"
                                                enterTo="transform opacity-100 scale-100"
                                                leave="transition ease-in duration-75"
                                                leaveFrom="transform opacity-100 scale-100"
                                                leaveTo="transform opacity-0 scale-95"
                                            >
                                                <Menu.Items className="absolute right-0 mt-2 w-56 origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                                                    <div className="px-1 py-1 ">
                                                        <Menu.Item>
                                                            {({ active }) => (
                                                                <button
                                                                    className={`${active ? 'bg-violet-500 text-white' : 'text-gray-900'
                                                                        } group flex w-full items-center rounded-md px-2 py-2 text-sm`}
                                                                >
                                                                    Edit
                                                                </button>
                                                            )}
                                                        </Menu.Item>
                                                    </div>
                                                </Menu.Items>
                                            </Transition>
                                        </Menu>
                                    </div>
                                </div>
                                <div className="hidden lg:block lg:ml-4">
                                    <div className="flex items-center rounded-full">

                                        <span
                                            onClick={() => setOpenSlideOverlay(true)}
                                        // className="flex text-sm text-white bg-blue-500 border-2 border-gray-200 rounded-full cursor-pointer dark:bg-gray-800 focus:outline-none focus:ring-2 focus:ring-offset-2 dark:focus:ring-offset-gray-900 focus:ring-offset-indigo-500 focus:ring-white"
                                        >


                                            {
                                                props.avatar_url &&

                                                <>
                                                    <div style={{ display: avatarImageLoading ? "block" : "none" }}>
                                                        <Avatar name={props.fullname && props.fullname[0]} size="28" round="30px" color="#F0F0F0" className="rounded-full h-7 w-7" />
                                                    </div>

                                                    <div style={{ display: avatarImageLoading ? "none" : "block" }}>

                                                        <img
                                                            alt=""
                                                            className="rounded-full h-7 w-7 "
                                                            src={`${values.user_image}`}
                                                            onLoad={() => setavatarImageLoading(false)}
                                                        />

                                                    </div>
                                                </>
                                            }

                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </>
                )}
            </Disclosure>

        </>
    )
}



export default Header;
